varying vec2 vUv;
varying float vAppearLeft;
varying float vAppearRight;
varying float posZ;
uniform float uTime;
uniform float uTransition;
uniform float uIntensity;
uniform float uNoiseSpeed;
uniform vec2 uResolution;

// 2D Random
float random (in vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))
                 * 43758.5453123);
}

// 2D Noise based on Morgan McGuire @morgan3d
// https://www.shadertoy.com/view/4dS3Wd
float noise (in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    // Smooth Interpolation

    // Cubic Hermine Curve.  Same as SmoothStep()
    vec2 u = f*f*(3.0-2.0*f);
    // u = smoothstep(0.,1.,f);

    // Mix 4 coorners percentages
    return mix(a, b, u.x) +
            (c - a)* u.y * (1.0 - u.x) +
            (d - b) * u.x * u.y;
}

void main() {
  vUv = uv;
  vec4 mvPosition = vec4(position, 1.0);

  #ifdef USE_INSTANCING
    mvPosition = instanceMatrix * mvPosition;
  #endif

  vec2 st = mvPosition.xz / vec2(1440., 900.);
  float n = noise(vec2(st.x * 165. + uTime * 0.06 + uNoiseSpeed, st.y * 165. + uTime * 0.06 - uNoiseSpeed));

  float smoothLeft = smoothstep(uTransition - 10., uTransition + 10., mvPosition.z + (1.0 - mvPosition.x) * 0.2);
  float smoothRight = 1.0 - smoothLeft;

  vAppearLeft = smoothLeft;
  vAppearRight = smoothRight;

  float smoothWave = smoothLeft * smoothRight;
  
  mvPosition.x += n;
  mvPosition.z += n;
  mvPosition.y += n + 1.4 + smoothWave + n * uIntensity * 0.75;
  mvPosition.y += (1.0 - mvPosition.z + 40.) * smoothWave * 0.1;

  posZ = n;
  
  vec4 modelViewPosition = modelViewMatrix * mvPosition;
  gl_Position = projectionMatrix * modelViewPosition;

}
