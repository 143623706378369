import { Body, Box, Quaternion, Sphere, Vec3 } from 'cannon-es'
import World from '../World'
import { Mesh, MeshStandardMaterial } from 'three'

export default class Rope {
  constructor(gl) {
    this.gl = gl
    this.world = new World()

    this.lastBody = null
    this.bodies = []
    this.meshes = []
    this.chainLinks = []
    this.scaleFactor = 0.1
    
    this.size = 1
		this.margin = this.size
		this.mass = 20
		this.ropeLength = 10
		this.lastBody = null
		this.shape = new Box(new Vec3(this.size * 0.5, this.size * 0.1, this.size * 0.5))

    // Get chain model
    this.chain = this.world.sources.chain.scene.children[0]

    this.chainMaterial = new MeshStandardMaterial({
      color: '#ffffff',
      metalness: 1,
      roughness: 0,
      envMap: this.world.sources.envmap,
      envMapIntensity: 1
    })

    // Global properties
    this.MASS = 11

    const initialPosition = 2
    
    this.createChain(0, initialPosition, 0)
    this.createChain(1, (-5 + initialPosition) * this.scaleFactor, this.MASS)
    this.createChain(2, (-20 + initialPosition) * this.scaleFactor, this.MASS)
    this.createChain(3, (-35 + initialPosition) * this.scaleFactor, this.MASS)

    this.world.isDebug && this.addGui()
  }

  addGui() {
		this.debug = {
			objectsNumber: 0,
      mass: this.MASS
		}

    this.debugChain = this.gl.debug.addFolder({
      title: '⛓️ Chain',
      expanded: false
    })

    this.debugChain.addInput(this.debug, 'mass', {
			label: 'Mass',
			min: 0,
			max: 200
		}).on('change', (e) => {
      for (let i = 0; i < this.chainLinks.length; i++) {
        const { body } = this.chainLinks[i]
  
        body.mass = e.value
        body.updateMassProperties()
      }
    })

    this.debugChain.addInput(this.chainMaterial, 'metalness', {
			label: 'Metalness',
			min: 0,
			max: 1
		}).on('change', (e) => this.updateValue(e.presetKey, e.value))

    this.debugChain.addInput(this.chainMaterial, 'roughness', {
			label: 'Roughness',
			min: 0,
			max: 1
		}).on('change', (e) => this.updateValue(e.presetKey, e.value))

    this.debugChain.addInput(this.chainMaterial, 'envMapIntensity', {
			label: 'Env map intensity',
			min: 0,
			max: 1
		}).on('change', (e) => this.updateValue(e.presetKey, e.value))
	}

  updateValue(prop, value) {
    for (let i = 0; i < this.chainLinks.length; i++) {
      const { mesh } = this.chainLinks[i]

      mesh.material[prop] = value
    }
  }

  createChain(index = 0, y = 0, mass = 0) {
    // Create THREE mesh
    const mesh = new Mesh(this.chain.geometry, this.chainMaterial.clone())

    mesh.scale.set(100 * this.scaleFactor, 100 * this.scaleFactor, 100 * this.scaleFactor)
    mesh.position.y = 12 + y
    mesh.position.z = -20

    // Create physic body
    const shapeSphere = new Sphere(1 * this.scaleFactor)
    const shapeBoxSide = new Box(new Vec3(1.2 * this.scaleFactor, 10 * this.scaleFactor, 1.2 * this.scaleFactor))
    const body = new Body({ mass })

    // Create half torus on top/bottom of the chain with multiple sphere to simulate a real torus
    body.addShape(shapeSphere, new Vec3(0, 12.3 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(2 * this.scaleFactor, 11.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(4 * this.scaleFactor, 10.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(-2 * this.scaleFactor, 11.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(-4 * this.scaleFactor, 10.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(2 * this.scaleFactor, -11.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(4 * this.scaleFactor, -10.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(-2 * this.scaleFactor, -11.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(-4 * this.scaleFactor, -10.8 * this.scaleFactor, 0))
    body.addShape(shapeSphere, new Vec3(0, -12.3 * this.scaleFactor, 0))

    // Left/right side of the chain link (we use a box shape to have collisions)
    body.addShape(shapeBoxSide, new Vec3(-4.75 * this.scaleFactor, 0, 0))
    body.addShape(shapeBoxSide, new Vec3(4.75 * this.scaleFactor, 0, 0))

    // Set body position to mesh position
    body.position.copy(mesh.position)

    // Rotate chain link one out of two
    if (index % 2 !== 0) {
      const quaternion = new Quaternion().setFromAxisAngle(new Vec3(0, 1, 0), Math.PI / 2)

      mesh.rotation.z = Math.PI / 2
      body.quaternion = quaternion
    } else {
      mesh.rotation.z = Math.PI
    }

    // Add body to physic world
    this.world.physic.world.addBody(body)

    // Add mesh to scene
    this.world.scene.add(mesh)

    this.chainLinks.push({
      mesh,
      body
    })
  }

  destroy() {
    this.debugChain && this.debugChain.dispose()

    for (let i = 0; i < this.chainLinks.length; i++) {
      const { mesh, body } = this.chainLinks[i]

      mesh.geometry.dispose()
      mesh.material.dispose()

      this.world.physic.world.removeBody(body)
      this.world.scene.remove(mesh)
    }

    this.chainLinks = []
  }
  
  update() {
    for (let i = 0; i < this.chainLinks.length; i++) {
      const { body, mesh } = this.chainLinks[i]
      const q = new Quaternion()
      q.setFromAxisAngle(new Vec3(1, 0, 0), Math.PI / 2)
      
      mesh.position.copy(body.position)
      mesh.quaternion.copy(body.quaternion.mult(q))
    }
  }
}
