import { gsap } from 'gsap'
import { AudioAnalyser, AudioListener, Audio } from 'three'
import World from '../World'
import store from '../store'

export default class Sound {
  constructor() {
    this.world = new World()
    this.grass = this.world.grass
    
    this.params = {
      volume: 0
    }

    this.canUpdateFrequency = true
    this.targetFrequency = 0
    this.currentFrequency = 0

    this.createAnimations()
  }

  createAudioContext() {
    const listener = new AudioListener()

    this.world.camera.instance.add(listener)

    this.audio = new Audio(listener)

    this.audio.setBuffer(this.world.modes[0].sound)

    this.analyser = new AudioAnalyser(this.audio, 32)

    this.oscillator = listener.context.createBiquadFilter()
    this.oscillator.type = 'lowpass'
    this.oscillator.frequency.value = 110

    this.audio.setFilter(this.oscillator)

    this.createFrequencyAnimation()
    
    this.audio.onEnded = () => {
      if (this.world.hud.isCustomOpen) {
        this.audio.stop()
        this.audio.play()
        
        this.createFrequencyAnimation()
      } else {
        this.world.changeMode()
      }
    }

    this.audio && this.audio.play()
  }

  createFrequencyAnimation() {
    store.cancelAnimation(this.frequencyTL)

    this.frequencyTL = gsap.timeline()

    this.frequencyTL
      .fromTo(this.oscillator.frequency, { value: 110 }, {
        value: 300,
        ease: 'linear',
        duration: this.world.modes[this.world.modeIndex].frequencyEnd
      })
      .fromTo(this.world.modes[this.world.modeIndex].frequency, { ...[this.world.modes[this.world.modeIndex].baseFrequency[0], this.world.modes[this.world.modeIndex].baseFrequency[1]] }, {
        ...[this.world.modes[this.world.modeIndex].baseFrequency[0] * 1.1, this.world.modes[1].baseFrequency[1] * 1.1],
        ease: 'linear',
        duration: this.world.modes[this.world.modeIndex].frequencyEnd
      }, '<')
      .to(this.oscillator.frequency, {
        value: 10000,
        ease: 'linear',
        duration: 3
      })
      .to(this.world.modes[this.world.modeIndex].frequency, {
        ...[this.world.modes[this.world.modeIndex].baseFrequency[0] * 2.5, this.world.modes[1].baseFrequency[1] * 2.5],
        ease: 'linear',
        duration: 3
      }, '<')
  }

  crossfade(sound) {
    return new Promise((resolve) => {
      const tl = gsap.timeline({
        defaults: { ease: 'linear' },
        onComplete: () => {
          resolve()
        }
      })
  
      tl
        .fromTo(this.volumeTween, { progress: 1 }, {
          progress: 0,
          duration: 0.4
        })
        .to(this.oscillator.frequency, {
          value: 110,
          duration: 0.4
        }, '<')
        .call(() => {
          this.createFrequencyAnimation()
        })
        .to(this.volumeTween, {
          progress: 1,
          duration: 0.4,
          onStart: () => {
            if (this.audio) {
              this.world.hud.isAudioEnabled && this.audio.stop()
              this.audio.setBuffer(sound)
              this.world.hud.isAudioEnabled && this.audio.play()
            }
          }
        })
    })
  }

  createAnimations() {
    this.volumeTween = gsap.to(this.params, {
      volume: 1,
      ease: 'linear',
      paused: true,
      onUpdate: () => {
        this.audio && this.audio.setVolume(this.volumeTween.progress())
      }
    })
  }

  update() {
    if (this.analyser) {
      const freq = Math.round(this.analyser.getAverageFrequency() / 256 * 100) / 100

      if (this.canUpdateFrequency) {
        this.targetFrequency = gsap.utils.clamp(0, 1, gsap.utils.mapRange(this.world.modes[this.world.modeIndex].frequency[0], this.world.modes[this.world.modeIndex].frequency[1], 0, 1, freq))
      } else {
        this.targetFrequency = 0
      }

      this.currentFrequency = store.lerp(this.currentFrequency, this.targetFrequency, 0.1)

      this.grass.instancedMesh.material.uniforms.uIntensity.value = this.currentFrequency
    }
  }
}
