import { Mesh, MeshStandardMaterial, BoxGeometry, Matrix4, Vector3, MeshPhongMaterial, CylinderGeometry } from 'three'
import { Body, Box, Cylinder, Quaternion, Vec3 } from 'cannon-es'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { gsap } from 'gsap'
import World from '../World'
import store from '../store'

export default class Pinata {
  constructor(gl) {
		this.gl = gl
    this.world = new World()
		
		this.hasFallen = false
		this.physicScaleFactor = 0.1
    this.size = 30
		this.maxObjectsNumber = store.device === 'desktop' ? 60 : 10
		this.impulseObjectsNumber = store.device === 'desktop' ? 5 : 3
		this.hitForce = new Vec3(0, 0, -7)
		this.objects = []

    this.scaleFactor = window.isTouchScreen ? 0.4 : 1
    this.papersCount = window.isTouchScreen ? 1 : 3

		// Global properties
		this.MASS = 1
		this.POSITION_Y = 2

		this.cylinderShape = new Cylinder(4, 4, 1, 24)
		this.pinataObjectsGeometry = new CylinderGeometry(4, 4, 1, 24)
		this.pinataObjectsMaterial= new MeshStandardMaterial({
			color: 'pink',
			transparent: true
		})

		this.createObjects()

		setTimeout(() => {
			this.create()
		}, 500)
		
		this.addDeadPinatas()
		this.world.isDebug && this.addGui()
  }

	addGui() {
		this.debug = {
			hitForce: this.hitForce.z,
			objectsNumber: 0,
			objectsMass: 1
		}

    this.debugPinata = this.gl.debug.addFolder({
			title: '🪅 Piñata',
			expanded: false
		})

		this.objectsCount = this.debugPinata.addBlade({
      view: 'text',
      label: 'Objects count',
      parse: (v) => String(v),
      value: '0',
      disabled: true
    })

		this.debugPinata.addInput(this.body, 'mass', {
			label: 'Mass',
			min: 0,
			max: 200
		}).on('change', () => {
			this.body.updateMassProperties()
		})

		this.debugPinata.addInput(this.debug, 'hitForce', {
			label: 'Impulse force',
			min: -20,
			max: 0
		}).on('change', (e) => {
			this.hitForce = new Vec3(0, 0, e.value)
		})

		this.debugPinata.addInput(this.debug, 'objectsMass', {
			label: 'Objects mass',
			min: 0,
			max: 1000
		}).on('change', (e) => {
			for (let i = 0; i < this.objects.length; i++) {
				const { body } = this.objects[i]

				body.mass = e.value
				body.updateMassProperties()
			}
		})
	}

	addObjectsCount() {
		this.objectsCount.controller_.valueController.value.rawValue = this.objects.length
	}

  createSheetUtils() {
    this.sheetGeometry = new BoxGeometry(1, 3, 0.1, 1, 5, 1)
    this.sheetGeometry.applyMatrix4( new Matrix4().makeTranslation(0, -1.5, 0))
    this.applyDisplacement(this.sheetGeometry)
    this.sheetGeometry.applyMatrix4( new Matrix4().makeTranslation( 0,-1.5,0 ))

    this.sheetMaterials = [
      new MeshPhongMaterial({ color: 'blue' }),
      new MeshPhongMaterial({ color: 'black' })
    ]
  }

	createObjects() {
		this.allObjects = []

		const metal = {
			color: '#fff',
			metalness: 1,
      roughness: 0,
      envMap: this.world.sources.envmap,
      envMapIntensity: 2
		}

		// Circle object
		const circleShape = new Cylinder(1, 1, 0.5, 24)

		const circle = {
			geometry:  new CylinderGeometry(0.8, 0.8, 0.5, 24),
			material: new MeshStandardMaterial({
				...metal
			}),
			shape: circleShape
		}

		// Hearth model
		const hearthModel = this.world.sources.hearth.scene.children[0]
		const hearthShape = new Cylinder(1, 1, 0.5, 24)

		hearthModel.geometry.scale(1.5, 1.5, 1.5)
		
		const hearth = {
			geometry: hearthModel.geometry,
			material: new MeshStandardMaterial({
				// color: 'red'
				...metal
			}),
			shape: hearthShape
		}

		// Star model
		const starModel = this.world.sources.star.scene.children[0]
		const starShape = new Cylinder(1, 1, 0.5, 24)

		starModel.geometry.scale(30, 30, 30)
		
		const star = {
			geometry: starModel.geometry,
			material: new MeshStandardMaterial({
				// color: 'blue'
				...metal
			}),
			shape: starShape
		}
		
		this.allObjects.push(circle, hearth, star)
	}

  create() {
		// Object's height
		const height = 0.8

		// Vertical handle boxes
		const box = new Box(new Vec3(1 * this.physicScaleFactor, 1 * this.physicScaleFactor, 0.5))

		// Horizontal handle box
		const boxWidth = new Box(new Vec3(0.8, 1 * this.physicScaleFactor, 1 * this.physicScaleFactor))

		const outerBox = new Box(new Vec3(3, 1, 3))
		const topBox = new Box(new Vec3(0.75, 1, 0.75))

		// Create physic body
		this.body = new Body({
			mass: this.MASS,
			velocity: new Vec3(0, 0, 0),
			collisionFilterGroup: this.world.physic.GROUP1,
			collisionFilterMask: this.world.physic.GROUP1
		})

		// Add shapes to physic body
		this.body.addShape(box, new Vec3(-0.65, 0, -height - 3.8))
		this.body.addShape(box, new Vec3(0.65, 0, -height - 3.8))
		this.body.addShape(boxWidth, new Vec3(0, 0, -height - 4.1))
		this.body.addShape(outerBox, new Vec3(0, 0, 0), new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), Math.PI / 4))
		this.body.addShape(topBox, new Vec3(0, 0, -3.5))

    this.body.position.set(0, this.POSITION_Y, -20)

		// Create quaternions to rotate physic body correctly to fit the THREE mesh rotation
		const qX = new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), Math.PI / 2)
    
		this.body.quaternion.copy(qX)

		this.geometry = this.world.sources.pinata.scene.children[0].geometry
		this.material = new MeshStandardMaterial({
			color: '#fff',
			metalness: 1,
      roughness: 0,
      envMap: this.world.sources.envmap,
      envMapIntensity: 3
		})
	
    this.mesh = new Mesh(this.geometry, this.material)

    this.mesh.rotation.x = Math.PI / 2
    this.mesh.rotation.z = -Math.PI / 2
		this.mesh.scale.set(2, 2, 2)

		this.body.addEventListener('collide', (e) => {
			if (e.body.name === 'ground' && !this.hasFallen) {
				this.hasFallen = true
				this.world.setRestartColors()
				this.world.hud.showRestartUI()
			}
		})

		setTimeout(() => {
			// Add body to physic world
			this.world.physic.world.addBody(this.body)

			// Add mesh to scene
			this.world.scene.add(this.mesh)
		}, 800)
  }

  hit(intersects) {
		this.hitForce.z -= 0.035

		if (!this.world.isDebug) this.hitForce.z = gsap.utils.clamp(-11, -7, this.hitForce.z)

    this.body.applyImpulse(this.hitForce, new Vec3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z))
		this.addObjects(intersects[0].point)
  }

	addDeadPinatas() {
		this.deadObjects = []

		let objects = [{
			x: -32,
			y: 8,
			z: -40
		}, {
			x: -26,
			y: 5,
			z: -38
		}, {
			x: -20,
			y: 10,
			z: -43
		}, {
			x: -12,
			y: 5,
			z: -45
		}, {
			x: -7,
			y: 8,
			z: -47
		}]

		if (store.device === 'mobile') {
			objects = [{
				x: -10,
				y: 5,
				z: -41
			}, {
				x: -5,
				y: 8,
				z: -44
			}]
		}

		const duplicatedArray = objects.map(obj => ({ ...obj, x: Math.abs(obj.x) }))

		// Add the duplicated elements in the original array
		objects = objects.concat(duplicatedArray)

		this.objectsPosition = objects

		const geometry = this.world.sources.pinataSimple.scene.children[0].geometry
		const material = new MeshStandardMaterial({
			color: '#fff',
			metalness: 1,
      roughness: 0,
      envMap: this.world.sources.envmap,
      envMapIntensity: 1
		})

		for (let i = 0; i < objects.length; i++) {
			const object = objects[i]
			const mesh = new Mesh(geometry, material.clone())
			const shape = new Box(new Vec3(3, 1.5, 3))
			const body = new Body({ mass: 2 })
			const qY = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), Math.PI / 4)
    
			mesh.scale.set(2, 2, 2)
			
			body.addShape(shape)
			body.position.x = object.x
			body.position.y = object.y
			body.position.z = object.z

			body.quaternion.copy(qY)

			this.deadObjects.push({
				mesh,
				body,
				velocityX: gsap.utils.random([-2, 2]),
				velocityY: gsap.utils.random([2.5, 5, 7.5])
			})

			this.world.physic.world.addBody(body)
			this.world.scene.add(mesh)

			body.applyImpulse(new Vec3(gsap.utils.random(-20, 20), 0, gsap.utils.random(-10, 10)), new Vec3(Math.random(), Math.random(), Math.random()))
		}
	}

	addObjects(pos) {
		// Remove objects for better performances
		this.objects.length > this.maxObjectsNumber && this.removeObjects()

		for (let i = 0; i < this.impulseObjectsNumber; i++) {
			// Get random object in objects lists
			const object = gsap.utils.random(this.allObjects)

			const mesh = new Mesh(object.geometry, object.material.clone())
			const body = new Body({
				sleepSpeedLimit: 0.15,
				mass: 1,
				shape: object.shape,
				collisionFilterGroup: this.world.physic.GROUP2,
				collisionFilterMask: this.world.physic.GROUP3
			})

			mesh.castShadow = true
			mesh.receiveShadow = true

			body.position.x = pos.x
			body.position.y = pos.y
			body.position.z = -40

			this.objects.push({
				mesh,
				body,
				velocityX: gsap.utils.random([-2, 2]),
				velocityY: gsap.utils.random([2, 3, 4])
			})

			if (!this.world.physic.isDestroying) {
				setTimeout(() => {
					this.world.physic.world.addBody(body)
					this.world.scene.add(mesh)
					// this.addObjectsCount()
	
					const x = gsap.utils.random(5, 25) * gsap.utils.random([-1, 1])
					const y = store.device === 'desktop' ? gsap.utils.random(4, 40) : gsap.utils.random(4, 25)
					const z = store.device === 'desktop' ? -gsap.utils.random(1, 1.75) : -gsap.utils.random(0.2, 1)
	
					body.applyImpulse(new Vec3(x, y, z), new Vec3(Math.random(), Math.random(), Math.random()))
				}, 150 * i)
			}
		}
	}

	removeObjects() {
		const objectsToRemove = this.objects.splice(0, 5)

		for (let j = 0; j < objectsToRemove.length; j++) {
			const { body, mesh } = objectsToRemove[j]
			const tl = gsap.timeline()

			tl
				.to(mesh.scale, {
					x: 0,
					y: 0,
					z: 0,
					ease: 'power2.out',
					duration: 0.35
				}, 0)
				.to(mesh.material, {
					opacity: 0,
					ease: 'power2.out',
					duration: 0.35,
					onComplete: () => {
						mesh.geometry.dispose()
						mesh.material.dispose()

						this.world.physic.world.removeBody(body)
						this.world.scene.remove(mesh)
					}
				}, 0)
		}
	}

	restart() {
		// Position
    this.body.position.set(0, this.POSITION_Y, -20)
		this.body.previousPosition.setZero()
		this.body.interpolatedPosition.setZero()
		this.body.initPosition.setZero()

		// orientation
		this.body.quaternion.set(0,0,0,1)
		this.body.initQuaternion.set(0,0,0,1)
		this.body.previousQuaternion.set(0,0,0,1)
		this.body.interpolatedQuaternion.set(0,0,0,1)

		// Velocity
		this.body.velocity.setZero()
		this.body.initVelocity.setZero()
		this.body.angularVelocity.setZero()
		this.body.initAngularVelocity.setZero()

		// Force
		this.body.force.setZero()
		this.body.torque.setZero()

		// Sleep state reset
		this.body.sleepState = 0
		this.body.timeLastSleepy = 0
		this.body._wakeUpAfterNarrowphase = false

		// Create quaternions to rotate physic body correctly to fit the THREE mesh rotation
		const qX = new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), Math.PI / 2)
    
		this.body.quaternion.copy(qX)

		setTimeout(() => {
			this.body.position.set(0, this.POSITION_Y, -20)
			this.hasFallen = false
			this.body.applyImpulse(new Vec3(0, 0, -15), new Vec3(0, 0, 0))
		}, 500)
	}

	onRestart() {
		this.hitForce.z = -7

		const randomIndex = Math.round(gsap.utils.random(0, this.deadObjects.length - 1))
		const object = this.deadObjects[randomIndex]

		object.body.position.y = 23
		object.body.position.z = Math.max(this.objectsPosition[randomIndex].z, -41)

		setTimeout(() => {
			object.body.wakeUp()
			object.body.applyImpulse(new Vec3(gsap.utils.random([-12, -8, -4, 4, 8, 12]), 0, gsap.utils.random([-10, -7, 7, 10])), new Vec3(Math.random(), Math.random(), Math.random()))
		}, 600)
	}

	onStart() {
		this.body.applyImpulse(new Vec3(0, 0, -15), new Vec3(0, 0, 0))
	}

	wakeUpObjects() {
		for (let i = 0; i < this.deadObjects.length; i++) this.deadObjects[i].body.wakeUp()
		for (let i = 0; i < this.objects.length; i++) this.objects[i].body.wakeUp()
	}

	pushDown() {
		for (let i = 0; i < this.deadObjects.length; i++) {
			const { body } = this.deadObjects[i]

			if (body.position.y > -1) {
				body.applyImpulse(new Vector3(0, -50, 0), new Vector3(0, 0, 0))
			}
		}
	}

	destroy() {
		for (let i = 0; i < this.objects.length; i++) {
			const { mesh, body } = this.objects[i]

			mesh.geometry.dispose()
			mesh.material.dispose()
			
			this.world.physic.world.removeBody(body)
			this.world.scene.remove(mesh)
		}
	}

	update() {
		if (!this.body) return

    this.mesh && this.mesh.position.copy(this.body.position)
    // this.mesh && this.mesh.quaternion.copy(this.body.quaternion)

		const q = new Quaternion()
      
		q.setFromAxisAngle(new Vec3(0, 1, 0), Math.PI)

		this.mesh && this.mesh.quaternion.copy(this.body.quaternion.mult(q))

    this.world.postProcessing && this.world.postProcessing.lightSource.position.copy(this.body.position)
    this.world.postProcessing && this.world.postProcessing.lightSource.quaternion.copy(this.body.quaternion)

		for (let i = 0; i < this.objects.length; i++) {
			const { mesh, body } = this.objects[i]

			mesh.position.copy(body.position)
			mesh.quaternion.copy(body.quaternion)
		}

		for (let i = 0; i < this.deadObjects.length; i++) {
			const { mesh, body } = this.deadObjects[i]

			mesh.position.copy(body.position)

			const q = new Quaternion()
      
			q.setFromAxisAngle(new Vec3(0, 1, 0), Math.PI / 4)

			mesh.quaternion.copy(body.quaternion.mult(q))
		}
  }
}
