import { PerspectiveCamera, Vector3 } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import World from './World'
import store from './store'
import gsap from 'gsap'

export default class Camera {
  constructor() {
    this.world = new World()

    this.scene = this.world.scene
    this.canvas = this.world.canvas
    this.canMove = true

    this.base = {
      position: new Vector3(0, 2, 7)
    }

    this.orientation = 0
    this.lerpAmount = store.device === 'desktop' ? 0.06 : 0.15

    this.lerpMouse = {
      x: 0,
      y: 0,
      rotate: 0
    }

    this.setInstance()
    this.setOrbitControls()
    this.world.isDebug && this.addGui()
  }

  addGui() {
    this.debugParams = { controls: false }
    this.debugCamera = this.world.debug.addFolder({
      title: '📹 Camera',
      expanded: false
    })
    
    this.debugCamera.addInput(this.debugParams, 'controls', { label: 'Controls' }).on('change', (e) => {
      this.controls.enabled = e.value

      if (!e.value) {
        this.controls.reset()
      }
    })

    this.debugCamera.addInput(this.base, 'position', { label: 'Position' }).on('change', (e) => {
      this.instance.position.set(this.base.position.x, this.base.position.y, this.base.position.z)
    })

    this.debugCamera.addInput(this.instance, 'target').on('change', () => {
  		this.instance.lookAt(this.instance.target)
    })
  }

  setInstance() {
    this.instance = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000)

    this.instance.position.set(0, 1, this.base.position.z)

    this.instance.target = new Vector3(0, 0, -20)
		this.instance.lookAt(this.instance.target)

    this.scene.add(this.instance)
  }

  setOrbitControls() {
    this.controls = new OrbitControls(this.instance, this.canvas)
    this.controls.enableDamping = true
    this.controls.enabled = false
    this.controls.reset()

    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.05

    this.controls.screenSpacePanning = false

    this.controls.maxDistance = 500

    this.controls.maxPolarAngle = Math.PI / 2

  }

  setBasePosition() {
    this.canMove = false
    this.instance.position.x = this.base.position.x
    this.instance.position.y = this.base.position.y

    this.instance.target = new Vector3(0, 0, -20)
  }

  resize() {
    this.instance.aspect = window.innerWidth/ window.innerHeight
    this.instance.updateProjectionMatrix()
  }

  update() {
    
    if (this.controls.enabled) {
      
      this.controls && this.controls.enabled && this.controls.update()

    } else {
      let x = this.world.mouse.normalize.x
      let y = this.world.mouse.normalize.y
      let xAmount = 1.5

      if (store.device === 'mobile' || store.device === 'tablet') {
        x *= 0.055
        y = gsap.utils.clamp(-45, 49, this.world.mouse.normalize.y)
        y = gsap.utils.mapRange(-45, 45, -60, 45, y) * 0.04
        xAmount = 1
      }
      
      if (this.canMove) {
        if (!isNaN(this.world.mouse.normalize.rotate)) {
          this.orientation = this.world.mouse.normalize.rotate

          if (this.orientation < 180) {
            this.orientation = 360 + this.orientation
          }

          this.orientation = gsap.utils.mapRange(470, 250, -7, 7, this.orientation)
          this.orientation = gsap.utils.clamp(-7, 7, this.orientation)

          this.world.physic.world.gravity.x = this.orientation
        }

        this.lerpMouse.x = store.lerp(this.lerpMouse.x, x, this.lerpAmount)
        this.lerpMouse.y = store.lerp(this.lerpMouse.y, y, this.lerpAmount)
        this.lerpMouse.rotate = store.lerp(this.lerpMouse.rotate, this.orientation * 2 * Math.PI / 180, 0.08)

        this.instance.position.x = this.base.position.x + xAmount * this.lerpMouse.x

        if (store.device === 'desktop') {
          this.instance.position.y = this.base.position.y - 1.5 * this.lerpMouse.y
        }
      }
      
      this.instance.lookAt(this.instance.target)

      this.instance.rotation.z = -this.lerpMouse.rotate * 1.38
    }
  }
}
