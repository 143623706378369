import { LinearSRGBColorSpace, NoColorSpace, SRGBColorSpace, WebGLRenderer } from 'three'
import World from './World'

export default class Renderer {
  constructor() {
    this.world = new World()

    this.canvas = this.world.canvas
    this.scene = this.world.scene
    this.camera = this.world.camera

    this.setInstance()
  }

  setInstance() {
    this.instance = new WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true
    })
    
    this.instance.outputColorSpace = LinearSRGBColorSpace

    this.instance.shadowMap.enabled = true
    this.instance.setSize(window.innerWidth, window.innerHeight)
    this.instance.setPixelRatio(window.devicePixelRatio)
  }

  resize() {
    this.instance.setSize(window.innerWidth, window.innerHeight)
    this.instance.setPixelRatio(window.devicePixelRatio)
  }

  update() {
    this.instance.render(this.scene, this.camera.instance)
  }
}
