import { PlaneGeometry, Mesh, Color, ShaderMaterial, Vector2 } from 'three'
import World from '../World'
import { Body, Plane, Vec3 } from 'cannon-es'
import fragmentShader from '../shader/ground/ground.frag'
import vertexShader from '../shader/ground/ground.vert'
import store from '../store'

export default class Ground {
  constructor(gl) {
    this.world = new World()
    this.gl = gl

    this.color = '#ac7ba7'

    if (store.device === 'mobile') {
      this.surfaceWidth = 25
    } else if (store.device === 'tablet') {
      this.surfaceWidth = 35
    } else {
      this.surfaceWidth = 70
    }

    this.create()
		this.world.isDebug && this.addGui()
  }

  create() {
    const geometry = new PlaneGeometry(this.surfaceWidth, 50)
    const material = new ShaderMaterial({
      fragmentShader,
      vertexShader,
      uniforms: {
        uColor: { value: this.world.modes[0].ground },
        uColor2: { value: this.world.modes[1].ground },
        uGrassGradient: { value: 0.5 },
        uTransition: { value: -30 },
        uResolution: { value: new Vector2(store.w.w, store.w.h) },
        uAlpha: { value:  1 }
      }
    })

    this.mesh = new Mesh(geometry, material)
    this.mesh.rotation.x = -Math.PI / 2
    this.mesh.position.y = -8
    this.mesh.position.z = -30

    // Physics
    this.shape = new Plane()
    this.body = new Body({ mass: 0 })
    this.body.addShape(this.shape)
    this.body.quaternion.setFromAxisAngle( new Vec3(1,0,0), -Math.PI * 0.5 )
		this.body.position.set(0, -6, -30)

    this.body.name = 'ground'

    // Add to physical world
    this.world.physic.world.addBody(this.body)

    // Add object to scene
    this.world.scene.add(this.mesh)
  }

  resize() {
    this.mesh.material.uniforms.uResolution.value = new Vector2(store.w.w, store.w.h)
  }

  addGui() {
    const params = {
      color: this.color,
      uTransition: 0
    }

    this.debugGround = this.gl.debug.addFolder({
      title: '🏞️ Ground',
      expanded: false
    })

    this.world.debugMode.addInput(params, 'color', { label: 'Ground' }).on('change', (e) => {
      this.mesh.material.uniforms.uColor2.value = new Color(e.value)
    })

    this.debugGround.addInput(this.mesh.material.uniforms.uAlpha, 'value', {
      label: 'uAlpha',
      min: 0,
      max: 1
    })

    this.debugGround.addInput(this.mesh.position, 'y', {
      min: -20,
      max: 20
    })

    this.debugGround.addInput(params, 'uTransition', {
      min: -1,
      max: 1
    }).on('change', (e) => {
      this.mesh.material.uniforms.uTransition.value = e.value
    })
  }
}
