import { ShaderMaterial, SphereGeometry, Mesh, Color, Vector2 } from 'three'
import { EffectComposer, EffectPass, GodRaysEffect, RenderPass, BloomEffect } from 'postprocessing'
import World from './World'
import fragmentShader from './shader/lightSource/lightSource.frag'
import vertexShader from './shader/lightSource/lightSource.vert'
import store from './store'

export default class PostProcessing {
  constructor(gl) {
    this.gl = gl
    this.world = new World()

    this.init()
    this.world.isDebug && this.addGui()
  }

  init() {
    this.createLight()

    this.composer = new EffectComposer(this.world.renderer.instance)
    this.renderPass = new RenderPass(this.world.scene, this.world.camera.instance)

    this.composer.addPass(this.renderPass)

    this.godRays = new GodRaysEffect(this.world.camera.instance, this.lightSource, {
      height: 480,
      kernelSize: 2,
      density: 1,
      decay: 0.9,
      weight: 0.5,
      exposure: 1,
      samples: 20,
      clampMax: 0.95
    })

    this.effectPass = new EffectPass(this.world.camera.instance, this.godRays)

    this.bloomEffect = new BloomEffect({
      width: store.w.w,
      height: store.w.h,
      luminanceSmoothing: 0.239,
      luminanceThreshold: 0,
      intensity: 1.2
    })

    this.bloomEffect.blendMode.opacity.value = 0.85

    const bloomPass = new EffectPass(this.world.camera.instance, this.bloomEffect)

    this.composer.addPass(this.effectPass)
    this.composer.addPass(bloomPass)
  }

  createLight() {
    const geometry = new SphereGeometry(1, 64)
    const material = new ShaderMaterial({
      fragmentShader,
      vertexShader,
      uniforms: {
        uTime: { value: 0 },
        uColor: { value: new Color(this.world.modes[0].light) }
      }
    })

    this.lightSource = new Mesh(geometry, material)

    this.lightSource.scale.set(1.5, 1.5, 1.5)

    this.world.scene.add(this.lightSource)
  }

  addGui() {
    const params = {
      color: '#' + this.world.modes[0].light.getHexString()
    }

    const postFxFolder = this.world.debug.addFolder({
      title: '✨ Post FX',
      expanded: false
    })
    const bloomFolder = postFxFolder.addFolder({ title: 'Bloom' })

    this.world.debugMode.addInput(params, 'color', {
      label: 'Godrays'
    }).on('change', (e) => {
      this.lightSource.material.uniforms.uColor.value = new Color(e.value)
    })

    bloomFolder.addInput(this.bloomEffect, 'intensity', {
      label: 'Intensity',
      min: 0,
      max: 10
    })

    bloomFolder.addInput(this.bloomEffect.luminanceMaterial, 'threshold', {
      label: 'Threshold',
      min: 0,
      max: 1
    })

    bloomFolder.addInput(this.bloomEffect.luminanceMaterial, 'smoothing', {
      label: 'Smoothing',
      min: 0,
      max: 1
    })

    bloomFolder.addInput(this.bloomEffect.blendMode.opacity, 'value', {
      label: 'Opacity',
      min: 0,
      max: 1
    })
  }

  update(e) {
    this.lightSource.material.uniforms.uTime.value = e

    this.composer.render()
  }
}
