import { TextureLoader, EquirectangularReflectionMapping, AudioLoader } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

export default class Resources {
  constructor() {
    this.gltfLoader = new GLTFLoader()
    this.textureLoader = new TextureLoader()
    this.audioLoader = new AudioLoader()
    this.rgbeLoader = new RGBELoader()

    this.loaded = 0
    this.path = '/'
    this.toLoad = [
      {
        name: 'envmap',
        file: 'envmap.hdr',
        loader: 'rgbeLoader'
      },
      {
        name: 'chain',
        file: 'chain.glb',
        loader: 'gltfLoader'
      },
      {
        name: 'hearth',
        file: 'hearth.gltf',
        loader: 'gltfLoader'
      },
      {
        name: 'star',
        file: 'little-star.glb',
        loader: 'gltfLoader'
      },
      {
        name: 'pinata',
        file: 'pinata.glb',
        loader: 'gltfLoader'
      },
      {
        name: 'pinataSimple',
        file: 'pinata-simple.glb',
        loader: 'gltfLoader'
      },
      {
        name: 'claude-francois',
        file: 'sound/claude-francois-merci-merci-beaucoup.mp3',
        loader: 'audioLoader'
      },
      {
        name: 'pop-smoke',
        file: 'sound/pop-smoke-merci-beaucoup.mp3',
        loader: 'audioLoader'
      },
      {
        name: 'lolo-zouai',
        file: 'sound/lolo-zouai-beaucoup.mp3',
        loader: 'audioLoader'
      },
      {
        name: 'nto',
        file: 'sound/nto-vivre-beaucoup.mp3',
        loader: 'audioLoader'
      },
      {
        name: 'caba-jj',
        file: 'sound/caba-jj-merci-beaucoup.mp3',
        loader: 'audioLoader'
      },
      {
        name: 'rey-cabrera',
        file: 'sound/rey-cabrera-merci-beaucoup.mp3',
        loader: 'audioLoader'
      }
    ]

    this.sources = {}
  }

  load() {
    return new Promise((resolve) => {
      for (let i = 0; i < this.toLoad.length; i++) {
        const { name, file, loader } = this.toLoad[i]

        if (loader === "gltfLoader") {
          this.gltfLoader.load(this.path + file, (object) => {
            this.sources[name] = object
            this.loaded++

            this.loaded === this.toLoad.length && resolve()
          })
        } else if (loader === "textureLoader") {
          this.textureLoader.load(this.path + file, (texture) => {
            this.sources[name] = texture
            this.loaded++

            this.loaded === this.toLoad.length && resolve()
          })
        } else if (loader === "rgbeLoader") {
          this.rgbeLoader.load(this.path + file, (texture) => {
            texture.mapping = EquirectangularReflectionMapping

            this.sources[name] = texture
            this.loaded++

            this.loaded === this.toLoad.length && resolve()
          })
        } else if (loader ===  'audioLoader') {
          this.audioLoader.load(this.path + file, (sound) => {
            this.sources[name] = sound
            this.loaded++

            this.loaded === this.toLoad.length && resolve()
          })
        }
      }
    })
  }
}
