import  { Color } from 'three'
import World from '../World'
import store from '../store'
import gsap from 'gsap'

export default class Form {
  constructor() {
    this.world = new World()
    this.sound = this.world.sound
    this.copyST = null

    this.bindMethods()
    this.getElems()
    this.events()

    this.selectedSoundIndex = 0

    this.addOptions()
  }

  bindMethods() {
    this.onColorGroundChange = this.onColorGroundChange.bind(this)
    this.onColorSkyChange = this.onColorSkyChange.bind(this)
    this.onShareClick = this.onShareClick.bind(this)
    this.onCopyClick = this.onCopyClick.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
  }
 
  getElems() {
    this.$inputColorGround = document.querySelector('.form-color-ground')
    this.$inputColorSky = document.querySelector('.form-color-sky')
    this.$select= document.querySelector('#sounds')
    this.$button = document.querySelector('.form-share')
    this.$copy = document.querySelector('.copy-link')
    this.$linkedin = document.querySelector('.share-linkedin')
    this.$twitter = document.querySelector('.share-twitter')
  }

  events() {
    this.$inputColorGround.addEventListener('input', this.onColorGroundChange)
    this.$inputColorSky.addEventListener('input', this.onColorSkyChange)
    this.$button.addEventListener('click', this.onShareClick)
    this.$copy.addEventListener('click', this.onCopyClick)
    this.$select.addEventListener('change', this.onSelectChange)

    this.$inputColorGround.value = '#' + this.world.modes[0].grass.getHexString()
    this.$inputColorSky.value = '#' + this.world.modes[0].world.getHexString()
  }

  addOptions() {
    for (let i = 0; i < this.world.sounds.length; i++) {
      const { name } = this.world.sounds[i]
      const option = document.createElement('option')

      option.value = i
      option.text = name

      this.$select.appendChild(option)
    }
  }

  onSelectChange() {
    this.selectedSoundIndex = Number(this.$select.value)

    const { sound, frequency } = this.world.sounds[this.selectedSoundIndex]

    this.world.updateCustomMode('sound', sound)
    this.world.updateCustomMode('frequency', frequency)

    this.world.modeIndex = this.world.modesLength
    this.changeSound(sound)

    this.updateLink()
  }

  changeSound(sound) {
    this.sound.canUpdateFrequency = false
    this.sound.crossfade(sound).then(() => {
      this.sound.canUpdateFrequency = true
    })
  }

  onShareClick() {
    this.$button.classList.add('a')
  }

  updateLink() {
    const url = new URL(window.location.origin)
    const mode = this.world.modes[this.world.modesLength]

    if (mode) {
      mode.grass && url.searchParams.append('g', mode.grass.getHexString())
      mode.world && url.searchParams.append('w', mode.world.getHexString())
    }

    this.selectedSoundIndex > 0 && url.searchParams.append('s', this.selectedSoundIndex)
    this.url = url

    const searchString = 'url='
    const linkedinIndex = this.$linkedin.href.indexOf(searchString)
    const twitterIndex = this.$twitter.href.indexOf(searchString)

    if (linkedinIndex !== -1) {
      this.$linkedin.href = this.$linkedin.href.substring(0, linkedinIndex) + 'url=' + encodeURIComponent(this.url)
    }

    if (twitterIndex !== -1) {
      this.$twitter.href = this.$twitter.href.substring(0, twitterIndex) + 'url=' + encodeURIComponent(this.url)
    }
  }

  onCopyClick() {
    clearTimeout(this.copyST)

    this.$copy.classList.add('a')
    this.updateLink()

    navigator.clipboard.writeText(this.url)

    this.copyST = setTimeout(() => {
      this.$copy.classList.remove('a')
    }, 1500)
  }

  onColorGroundChange() {
    const darkenColor = store.adjustColor(this.$inputColorGround.value, -75)
    
    this.world.grass.instancedMesh.material.uniforms[this.world.currentColor].value = new Color(this.$inputColorGround.value)
    this.world.ground.mesh.material.uniforms[this.world.currentColor].value = new Color(darkenColor)

    this.world.updateCustomMode('grass', new Color(this.$inputColorGround.value))
    this.world.updateCustomMode('ground', new Color(darkenColor))

    this.updateLink()
  }

  onColorSkyChange() {
    const lightenColor = store.adjustColor(this.$inputColorSky.value, 125)

    this.world.addFog(this.$inputColorSky.value)
    this.world.postProcessing.lightSource.material.uniforms.uColor.value = new Color(lightenColor)

    this.world.updateCustomMode('world', new Color(this.$inputColorSky.value))
    this.world.updateCustomMode('light', new Color(lightenColor))

    this.updateLink()
  }
}
