import { Body, Trimesh, Vec3, World } from 'cannon-es'
import CannonDebugger from 'cannon-es-debugger'

export default class Physic {
  constructor(gl) {
    this.gl = gl
    this.world = new World({
      gravity: new Vec3(0, -40, 0),
      allowSleep: true,
    })

    this.isDestroying = false

    // Contact groups
    this.GROUP1 = 1
    this.GROUP2 = 2
    this.GROUP3 = 3

    this.world.isDebug && this.addGui()
  }
  
  addGui() {
    this.cannonDebugger = new CannonDebugger(this.gl.scene, this.world, {
      onInit: (body, mesh) => {
        this.showDebuggerGUI.on('change', (e) => {
          mesh.visible = e.value
        })
      }
    })

    this.gui = {
      debug: false,
      gravity: {
        x: 0,
        y: this.world.gravity.y,
        z: 0
      }
    }
    this.debugPhysic = this.gl.debug.addFolder({
      title: '🏃🏻 Physic',
      expanded: false
    })
    
    this.showDebuggerGUI = this.debugPhysic.addInput(this.gui, 'debug', { label: 'Debug' })

    // Gravity X
    this.debugPhysic.addInput(this.gui.gravity, 'x', {
      label: 'Gravity X',
      min: -200,
      max: 200
    }).on('change', (e) => {
      this.world.gravity.x = e.value
    })
    
    // Gravity Y
    this.debugPhysic.addInput(this.gui.gravity, 'y', {
      label: 'Gravity Y',
      min: -200,
      max: 200
    }).on('change', (e) => {
      this.world.gravity.y = e.value
    })

    // Gravity Z
    this.debugPhysic.addInput(this.gui.gravity, 'z', {
      label: 'Gravity Z',
      min: -200,
      max: 200
    }).on('change', (e) => {
      this.world.gravity.z = e.value
    })
  }

  createTrimeshShape(geometry) {
    const vertices = []

    geometry.attributes.position.array.forEach((vertex, index) => {
        if (index % 3 === 0) {
            vertices.push(vertex, geometry.attributes.position.array[index + 1], geometry.attributes.position.array[index + 2])
        }
    })

    const indices = []

    for (let i = 0; i < geometry.index.array.length; i += 3) {
        indices.push(geometry.index.array[i], geometry.index.array[i + 1], geometry.index.array[i + 2])
    }

    const shape = new Trimesh(vertices, indices)

    return shape
  }

  createBody(shape, mass = 0) {
    const body = new Body({
      mass,
      shape
    })

    return body
  }

  update() {
    this.cannonDebugger && this.gui.debug && this.cannonDebugger.update()

    // Update physic world in rAF
    !this.isDestroying && this.world.fixedStep()
  }
}
