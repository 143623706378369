varying vec2 vUv;
varying float posZ;
uniform vec3 uColor;
uniform vec3 uColor2;
uniform float uGrassGradient;
uniform float uTime;
uniform vec3 fogColor;
uniform float fogNear;
uniform float fogFar;
uniform float uTransition;
uniform float uAlpha;
uniform vec2 uResolution;

void main() {
    vec2 st = gl_FragCoord.xy / uResolution.xy;
    vec3 baseColor = uColor;

    float smoothLeft = smoothstep(1. - uTransition - 0.1, 1. - uTransition + 0.1, st.y - (1. - st.x) * 0.1);
    float smoothRight = 1.0 - smoothLeft;

    float vAppearLeft = smoothLeft;
    float vAppearRight = smoothRight;

    vec3 grassColor1 = uColor;
    vec3 finalColor1 = grassColor1;

    vec3 grassColor2 = uColor2;
    vec3 finalColor2 = grassColor2;

    vec3 color1 = uColor2 * vec3(vAppearLeft);
    vec3 color2 = uColor * vec3(vAppearRight);

    vec3 stepsColor = color1 + color2;

    gl_FragColor = vec4(stepsColor, uAlpha);
}
