import { Color, DirectionalLight, DirectionalLightHelper, PointLight, PointLightHelper, SpotLight, SpotLightHelper } from 'three'
import World from './World'

export default class Lights {
  constructor() {
    this.world = new World()

    if (this.world.isDebug) {
      this.debugLights = this.world.debug.addFolder({
        title: '🔦 Lights',
        expanded: false
      })
    }

    this.createPointLight()
    this.createDirectionalLight()
    this.createSpotLight()
  }

  createPointLight() {
    this.pointLight = new PointLight('#fff', 1, 100)
    this.pointLight.position.set(0, 50, 50)
    this.pointLight.castShadow = true

    this.world.scene.add(this.pointLight)

    const helper = new PointLightHelper(this.pointLight, 1)

    helper.visible = false

    this.world.scene.add(helper)

    this.world.isDebug && this.addDebug(this.pointLight, 'Point light', helper)
  }

  createDirectionalLight() {
    this.directionalLight = new DirectionalLight('#fff', 1)
    this.directionalLight.position.set(0, 50, -200)
    this.directionalLight.castShadow = true

    this.world.scene.add(this.directionalLight)

    const helper = new DirectionalLightHelper(this.directionalLight, 1)

    helper.visible = false

    this.world.scene.add(helper)

    this.world.isDebug && this.addDebug(this.directionalLight, 'Directional light', helper)
  }

  createSpotLight() {
    this.spotLight = new SpotLight('#fff', 1)
    this.spotLight.position.set(-125, 200, 200)
    this.spotLight.castShadow = true

    this.world.scene.add(this.spotLight)

    const helper = new SpotLightHelper(this.spotLight, 1)

    helper.visible = false

    this.world.scene.add(helper)

    this.world.isDebug && this.addDebug(this.spotLight, 'Spot light', helper)
  }

  addDebug(light, title, helper) {
    const folder = this.debugLights.addFolder({ title })
    const params = {
      color: '#ffffff',
      showHelper: false
    }

    folder.addInput(light.position, 'x', {
      min: -200,
      max: 200
    })

    folder.addInput(light.position, 'y', {
      min: -200,
      max: 200
    })

    folder.addInput(light.position, 'z', {
      min: -200,
      max: 200
    })

    folder.addInput(params, 'color').on('change', (e) => {
      light.color = new Color(e.value)
    })

    folder.addInput(light, 'intensity', {
      min: 0,
      max: 1
    })

    helper && folder.addInput(params, 'showHelper', {
      label: 'Show helper'
    }).on('change', (e) => {
      helper.visible = e.value
    })
  }
}
