import { PlaneGeometry, InstancedMesh, DoubleSide, ShaderMaterial, Object3D, Color, Vector2 } from 'three'
import World from '../World'
import fragmentShader from '../shader/grass/grass.frag'
import vertexShader from '../shader/grass/grass.vert'
import store from '../store'

export default class Grass {
  constructor(gl) {
		this.gl = gl
    this.world = new World()
    this.color = this.world.modes[0].grass

    this.setDefault()
    this.create()
    this.setGrassPosition()

    this.world.isDebug && this.addGui()
  }

  setDefault() {
    if (store.device === 'mobile') {
      this.instanceNumber = 75000
      this.surfaceWidth = 25
      this.surfaceHeight = 50
    } else if (store.device === 'tablet') {
      this.instanceNumber = 100000
      this.surfaceWidth = 35
      this.surfaceHeight = 50
    } else {
      this.instanceNumber = 240000
      this.surfaceWidth = 70
      this.surfaceHeight = 50
    }
  }

  create() {
    const geometry = new PlaneGeometry(0.1, 0.6, 1, 4)

    // Move grass blade geometry lowest point at 0
    geometry.translate(0, 0.5, 0)

    this.material = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      fog: true,
      uniforms: {
        fogColor: { value: new Color(this.world.modes[0].world) },
        fogNear: { value: this.world.scene.fog.near },
        fogFar: { value: this.world.scene.fog.far },
        uTime: { value: 0 },
        uLight: { value: 1 },
        uColor: { value: this.world.modes[0].grass },
        uColor2: { value: this.world.modes[1].grass },
        uGrassGradient: { value: 0.5 },
        uTransition: { value: -30 },
        uIntensity: { value: 0 },
        uNoiseSpeed: { value: 0 },
        uResolution: { value: new Vector2(store.w.w, store.w.h) }
      },
      side: DoubleSide
    })

    this.instancedMesh = new InstancedMesh(geometry, this.material, this.instanceNumber)

    this.instancedMesh.position.y = -8
    this.instancedMesh.position.z = -27
    this.world.scene.add(this.instancedMesh)
  }

  dispose() {
    this.instancedMesh.dispose()
    this.world.scene.remove(this.instancedMesh)
  }

  addGui() {
    const params = {
      instanceNumber: this.instanceNumber,
      width: this.surfaceWidth,
      height: this.surfaceHeight,
      position: this.instancedMesh.position,
      color: '#' + this.color.getHexString(),
      uTransition: this.instancedMesh.material.uniforms.uTransition.value,
      uGrassGradient: this.instancedMesh.material.uniforms.uGrassGradient.value,
      uNoiseSpeed: this.instancedMesh.material.uniforms.uNoiseSpeed.value
    }

    this.debugGrass = this.gl.debug.addFolder({
      title: '🌿 Grass',
      expanded: false
    })

    this.debugGrass.addInput(params, 'width', {
      min: 0,
      max: 500,
      label: 'Surface width'
    }).on('change', (e) => {
      this.surfaceWidth = e.value

      this.dispose()
      this.create()
      this.setGrassPosition()
    })

    this.debugGrass.addInput(params, 'height', {
      min: 0,
      max: 500,
      label: 'Surface height'
    }).on('change', (e) => {
      this.surfaceHeight = e.value

      this.dispose()
      this.create()
      this.setGrassPosition()
    })

    this.debugGrass.addInput(params, 'instanceNumber', {
      min: 0,
      max: 500000,
      label: 'Instance number'
    }).on('change', (e) => {
      this.instanceNumber = e.value

      this.dispose()
      this.create()
      this.setGrassPosition()
    })

    this.debugGrass.addInput(this.instancedMesh.scale, 'y', {
      min: 0,
      max: 2,
      label: 'Height'
    }).on('change', (e) => {
      this.instancedMesh.scale.y = e.value
    })

    this.debugGrass.addInput(params.position, 'x', {
      min: -200,
      max: 200
    }).on('change', (e) => {
      this.instancedMesh.position.x = e.value
    })

    this.debugGrass.addInput(params.position, 'y', {
      min: -200,
      max: 200
    }).on('change', (e) => {
      this.instancedMesh.position.y = e.value
    })

    this.debugGrass.addInput(params.position, 'z', {
      min: -200,
      max: 200
    }).on('change', (e) => {
      this.instancedMesh.position.z = e.value
    })

    this.debugGrass.addInput(params, 'uGrassGradient', {
      min: 0,
      max: 1
    }).on('change', (e) => {
      this.instancedMesh.material.uniforms.uGrassGradient.value = e.value
    })

    this.debugGrass.addInput(params, 'uTransition', {
      min: -30,
      max: 30
    }).on('change', (e) => {
      this.instancedMesh.material.uniforms.uTransition.value = e.value
    })

    this.debugGrass.addInput(params, 'uNoiseSpeed', {
      min: 0,
      max: 10
    }).on('change', (e) => {
      this.instancedMesh.material.uniforms.uNoiseSpeed.value = e.value
    })

    this.world.debugMode.addInput(params, 'color', {
      label: 'Grass'
    }).on('change', (e) => {
      this.instancedMesh.material.uniforms.uColor2.value = new Color(e.value)
    })
  }

  setGrassPosition() {
    const dummy = new Object3D()

    for (let i = 0; i < this.instanceNumber; i++) {
      dummy.position.set((Math.random() - 0.5) * this.surfaceWidth, 0, (Math.random() - 0.5) * this.surfaceHeight)
      
      dummy.scale.setScalar(0.5 + Math.random() * 0.5) 
      dummy.rotation.y = Math.random() * Math.PI
      
      dummy.updateMatrix()
      
      this.instancedMesh.setMatrixAt(i, dummy.matrix)
    }
  }

  resize() {
    this.instancedMesh.material.uniforms.uResolution.value = new Vector2(store.w.w, store.w.h)
  }

  update() {
    this.material.uniforms.uTime.value += 0.1
    this.material.uniformsNeedUpdate = true
  }
}
