uniform vec3 uColor;
uniform vec3 uColor2;
uniform vec3 fogColor;

uniform vec2 uResolution;

varying float vAppearRight;
varying float vAppearLeft;
varying float posZ;
uniform float uGrassGradient;
uniform float uTime;
uniform float uIntensity;
uniform float uLight;
uniform float fogNear;
uniform float fogFar;

varying vec2 vUv;

// 2D Random
float random (in vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))
                 * 43758.5453123);
}

// 2D Noise based on Morgan McGuire @morgan3d
// https://www.shadertoy.com/view/4dS3Wd
float noise (in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    // Smooth Interpolation

    // Cubic Hermine Curve.  Same as SmoothStep()
    vec2 u = f*f*(3.0-2.0*f);
    // u = smoothstep(0.,1.,f);

    // Mix 4 coorners percentages
    return mix(a, b, u.x) +
            (c - a)* u.y * (1.0 - u.x) +
            (d - b) * u.x * u.y;
}

void main() {
    vec2 st = gl_FragCoord.xy / uResolution.xy;

    vec2 pos = vec2(st * 3.0);

    pos.y -= uTime * 0.2;

    vec3 baseColor = uColor;

    float clarity = (vUv.y * 0.5) + uGrassGradient;

    vec3 grassColor1 = uColor * clarity;
    vec3 finalColor1 = grassColor1 + vec3(posZ) * 0.25;

    vec3 grassColor2 = uColor2 * clarity;
    vec3 finalColor2 = grassColor2 + vec3(posZ) * 0.25;

    vec3 color1 = uColor * uLight * vec3(vAppearLeft);
    vec3 color2 = uColor2 * uLight * vec3(vAppearRight);

    vec3 stepsColor = finalColor1 * color1 + finalColor2 * color2 + .1 * uIntensity * posZ;

    gl_FragColor = vec4(stepsColor, 1.);

    #ifdef USE_FOG
        #ifdef USE_LOGDEPTHBUF_EXT
            float depth = gl_FragDepthEXT / gl_FragCoord.w;
        #else
            float depth = gl_FragCoord.z / gl_FragCoord.w;
        #endif
        float fogFactor = smoothstep( fogNear, fogFar, depth );
        
        gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
    #endif
}
