import { Scene, AmbientLight, DirectionalLight, Raycaster, Fog, Color } from 'three'
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { Pane } from 'tweakpane'
import store from './store'
import * as EssentialsPlugin from '@tweakpane/plugin-essentials'
import Camera from './Camera'
import Renderer from './Renderer'
import Physic from './Physic'
import Pinata from './game/Pinata'
import Ground from './game/Ground'
import Rope from './game/Rope'
import Lights from './Lights'
import Grass from './game/Grass'
import PostProcessing from './PostProcessing'
import Hud from './game/Hud'
import Sound from './game/Sound'
import Form from './game/Form'

let instance = null

export default class World {
  constructor(props) {
    if (instance) return instance

    instance = this
    window.world = this

    // Global params
    this.isDebug = false
    this.color = '#000835'
    this.restartColor = '65, 0, 163'
    this.changeModeDuration = 1.75
    this.index = 0
    this.currentColor = 'uColor'
    this.isStarted = false
    this.isAnimatingMode = true
    this.mouse = {
      x: 0,
      y: 0,
      normalize: {
        x: 0,
        y: 0
      }
    }

    this.debugParams = {
      enableCheat: false
    }

    this.params = {
      modeProgress: 0
    }

    // GUI
    if (this.isDebug) {
      this.addDebug()
    }

    this.canvas = document.querySelector('#gl')
    this.sources = props.sources

    store.w = {
      w: window.innerWidth,
      h: window.innerHeight,
      dpr: window.devicePixelRatio
    }

    this.checkDevice()
    this.createSounds()
    this.createModes()
    this.bindMethods()
    this.events()
    this.isDebug && this.addGui()

    // Setup
    this.scene = new Scene()
    this.camera = new Camera()
    this.lights = new Lights()
    this.renderer = new Renderer()
    this.raycaster = new Raycaster()
    this.physic = new Physic(this)

    this.addFog(this.modes[0].world)
    
    // Init experience
    this.ground = new Ground(this)
    this.grass = new Grass(this)
    this.sound = new Sound()
    this.rope = new Rope(this)
    this.pinata = new Pinata(this)
    this.postProcessing = new PostProcessing(this)
    this.hud = new Hud()
    this.form = new Form()
    
    this.addLights()
    this.init()
  }

  bindMethods() {
    this.click = this.click.bind(this)
    this.move = this.move.bind(this)
  }

  events() {
    document.documentElement.addEventListener('click', this.click)
    !store.detect.isMobile && window.addEventListener('mousemove', this.move)
  }

  init() {
    setTimeout(() => {
      this.hud.showLoader()
    }, 300)
  }

  addDebug() {
    this.debug = new Pane({
      title: 'GUI',
      expanded: false
    })
    this.debug.registerPlugin(EssentialsPlugin)

    this.fpsGraph = this.debug.addBlade({
      view: 'fpsgraph',
      label: 'FPS',
      lineCount: 2
    })

    this.debug.addInput(this.debugParams, 'enableCheat', { label: 'Enable cheat code' })
      .on('change', (e) => {
        this.pinata.hitForce.z = e.value ? -50 : -7
      })

    this.debugMode = this.debug.addFolder({
      title: '🎨 Color mode',
      expanded: true
    })
  }

  addGyroscope() {
    if (!store.detect.isMobile) return

    if (DeviceOrientationEvent.requestPermission) {
      DeviceOrientationEvent.requestPermission().then((result) => {
        if (result === 'granted') {
          window.addEventListener("deviceorientation", (event) => {
            this.mouse = {
              normalize: {
                x: event.gamma,
                y: event.beta,
                rotate: event.alpha
              }
            }
          })
        }
      })
    }
  }

  addGui() {
    const params = {
      color: this.color,
      duration: this.changeModeDuration,
      audioPlay: true,
      frequency: 110
    }
    
    this.debugMode.addButton({ title: 'Animate' }).on('click', () => this.changeMode())

    this.debugMode.addBlade({
      view: 'cubicbezier',
      value: [0.32, 0.94, 0.6, 1],
      expanded: true,
      label: 'Easing',
      picker: 'inline'
    }).on('change', (e) => {
      CustomEase.create('beaucoup.mode', e.value.comps_.toString())
    })

    this.debugMode.addInput(params, 'duration', {
      label: 'Duration',
      min: 0.3,
      max: 3
    }).on('change', (e) => {
      this.changeModeDuration = e.value
    })

    this.debugMode.addInput(params, 'color', { label: 'World' }).on('change', (e) => {
      this.scene.fog.color = new Color(e.value)
      this.scene.background = new Color(e.value)
    })

    this.debugSound = this.debug.addFolder({
      title: '🔉 Sound',
      expanded: false
    })

    this.debugSound.addButton({ title: 'Play/pause sound '}).on('click', (e) => {
      params.audioPlay = !params.audioPlay
      params.audioPlay ? this.sound.audio.play() : this.sound.audio.pause()
    })

    this.debugSound.addInput(params, 'frequency', {
      min: 0,
      max: 1000
    }).on('change', (e) => {
      this.oscillator.frequency.setValueAtTime(e.value, this.sound.audio.context.currentTime)
    })
  }

  createSounds() {
    this.sounds = [
      {
        name: 'Beaucoup ✧ Lolo Zouaï',
        sound: this.sources['lolo-zouai'],
        frequency: [0.19, 0.21],
        frequencyEnd: 3
      },
      {
        name: 'Merci beaucoup ✧ Pop Smoke',
        sound: this.sources['pop-smoke'],
        frequency: [0.18, 0.22],
        frequencyEnd: 5.5
      },
      {
        name: 'Merci beaucoup ✧ Caballero & JeanJass',
        sound: this.sources['caba-jj'],
        frequency: [0.155, 0.17],
        frequencyEnd: 4
      },
      {
        name: 'Merci beaucoup ✧ Rey Cabrera',
        sound: this.sources['rey-cabrera'],
        frequency: [0.19, 0.21],
        frequencyEnd: 5
      },
      {
        name: 'Merci merci beaucoup ✧ Claude François',
        sound: this.sources['claude-francois'],
        frequency: [0.19, 0.21],
        frequencyEnd: 5
      },
      {
        name: 'Vivre Beaucoup ✧ Nto',
        sound: this.sources['nto'],
        frequency: [0.2, 0.23],
        frequencyEnd: 3
      }
    ]

    for (let i = 0; i < this.sounds.length; i++) {
      this.sounds[i].baseFrequency = [this.sounds[i].frequency[0], this.sounds[i].frequency[1]]
    }
  }

  createModes() {
    this.modeIndex = 0
    this.modes = [
      {
        world: new Color('#00093c'),
        ground: new Color('#667082'),
        grass: new Color('#acc5ff'),
        light: new Color('#cdd6f8'),
        ...this.sounds[2] // Caba JJ
      },
      {
        ground: new Color('#a8a8a8'), 
        grass: new Color('#f5f5f5'),
        world: new Color('#000'),
        light: new Color('#fff'),
        ...this.sounds[1] // Pop smoke
      },
      {
        world: new Color('#D841BF'),
        ground: new Color('#d28aca'),
        grass: new Color('#FFCCEF'),
        light: new Color('#ffd3f6'),
        ...this.sounds[0] // Lolo
      },
      {
        ground: new Color('#7c5378'),
        grass: new Color('#e166fa'),
        world: new Color('#43046a'),
        light: new Color('#f0cdf8'),
        ...this.sounds[5] // NTO
      },
      {
        world: new Color('#e08300'),
        ground: new Color('#c5a682'),
        grass: new Color('#fddeba'),
        light: new Color('#f8eacd'),
        ...this.sounds[4] // Cloclo
      },
      {
        world: new Color('#42B7FF'),
        ground: new Color('#cfbe00'),
        grass: new Color('#FFEA00'),
        light: new Color('#daf1ff'),
        ...this.sounds[3] // Rey
      }
    ]

    this.addCustomMode()

    this.modesLength = this.modes.length
  }

  addCustomMode() {
    const qS = window.location.search
    const params = new URLSearchParams(qS)

    if (qS && params.get('g') && params.get('w')) {
      const currentSound = params.get('s') || 0
      const currentGrass = params.get('g') || this.modes[0].grass.getHexString()
      const currentWorld = params.get('w') || this.modes[0].world.getHexString()

      const darkenColor = store.adjustColor(currentGrass, -75)
      const lightenColor = store.adjustColor(currentWorld, 125)

      const customMode = {
        ground: new Color(darkenColor),
        grass: new Color('#' + currentGrass),
        world: new Color('#' + currentWorld),
        light: new Color(lightenColor),
        sound: this.sounds[currentSound].sound,
        frequency: this.sounds[currentSound].frequency,
        baseFrequency: this.sounds[currentSound].frequency
      }

      this.modes.unshift(customMode)
    }
  }

  updateCustomMode(key, value) {
    if (!this.modes[this.modesLength]) this.modes[this.modesLength] = { ...this.modes[this.modeIndex] }

    this.modeIndex = this.modesLength
    this.modes[this.modesLength][key] = value
  }

  addFog(color) {
    this.scene.background = new Color(color)
    this.fog = new Fog(color, 1, 58)

    this.scene.fog = this.fog
  }

  addLights() {
    const shadowLight = new DirectionalLight(0xffffff, 0.6)

		shadowLight.position.set(0, 50, 50)
		shadowLight.target.position.set(0, 0, 0)
		shadowLight.castShadow = true

		this.scene.add(shadowLight)

    const ambientLight = new AmbientLight(0xffffff, 1)

		this.scene.add(ambientLight)
  }

  onStart() {
    this.sound.createAudioContext()
  }

  click(e) {
    this.mouseClick = {
      x: (e.clientX / window.innerWidth) * 2 - 1,
      y: - (e.clientY / window.innerHeight) * 2 + 1
    }

		this.raycaster.setFromCamera(this.mouseClick, this.camera.instance)

    !this.pinata.hasFallen && this.isStarted && this.hit()
  }

  move(e) {
    this.mouse.x = e.clientX
    this.mouse.y = e.clientY

    this.mouse.normalize.x = gsap.utils.mapRange(0, window.innerWidth, -1, 1, this.mouse.x)
    this.mouse.normalize.y = gsap.utils.mapRange(0, window.innerHeight, -1, 1, this.mouse.y)
  }

  hit() {
    const intersects = this.raycaster.intersectObject(this.pinata.mesh)

    // Hit piñata
    if (intersects.length > 0) {
      this.pinata.hit(intersects)
    }
  }

  changeMode() {
    if (this.isAnimatingMode) return

    this.sound.canUpdateFrequency = false
    this.isAnimatingMode = true
    this.lastModeIndex = this.modeIndex
    this.modeIndex = gsap.utils.wrap(0, this.modes.length, this.modeIndex + 1)
    
    this.currentColor = 'uColor2'
    
    this.grass.instancedMesh.material.uniforms.uColor.value = this.modes[this.lastModeIndex].grass
    this.ground.mesh.material.uniforms.uColor.value = this.modes[this.lastModeIndex].ground
    
    this.grass.instancedMesh.material.uniforms.uColor2.value = this.modes[this.modeIndex].grass
    this.ground.mesh.material.uniforms.uColor2.value = this.modes[this.modeIndex].ground
    
    const tl = gsap.timeline({
      defaults: {
        duration: this.changeModeDuration,
        ease: 'beaucoup.mode'
      },
      onStart: () => {
        this.sound.crossfade(this.modes[this.modeIndex].sound)
        this.pinata.pushDown()
      },
      onComplete: () => {
        this.sound.canUpdateFrequency = true
      }
    })

    this.postProcessing && tl.to(this.postProcessing.lightSource.material.uniforms.uColor.value, {
      r: this.modes[this.modeIndex].light.r,
      g: this.modes[this.modeIndex].light.g,
      b: this.modes[this.modeIndex].light.b
    }, 0)

    tl
      .to([this.scene.fog.color, this.scene.background], {
        r: this.modes[this.modeIndex].world.r,
        g: this.modes[this.modeIndex].world.g,
        b: this.modes[this.modeIndex].world.b
      }, 0)
      .fromTo(this.params, { modeProgress: 0 }, {
        modeProgress: 1,
        duration: 0.4,
        onUpdate: () => {
          this.hud.params.noiseSpeed.targ += 0.03

          for (let i = 0; i < this.pinata.deadObjects.length; i++) {
            const object = this.pinata.deadObjects[i]
          
            object.body.velocity.x = object.velocityX
            object.body.velocity.y = object.velocityY
          }
  
          for (let i = 0; i < this.pinata.objects.length; i++) {
            const object = this.pinata.objects[i]
          
            object.body.velocity.x = object.velocityX
            object.body.velocity.y = object.velocityY
          }
        },
        onComplete: () => {
          this.pinata.pushDown()
        }
      }, 0)
      .to(this.camera.instance.position, {
        z: this.camera.base.position.z,
        ease: 'power3.out',
        duration: 1
      }, 0.5)
      .to(this.postProcessing.bloomEffect, {
        intensity: 1.2,
        ease: 'power3.out',
        duration: 1
      }, 0.5)
      .to(this.hud.$modeLineCurrent, {
        scaleX: 0,
        transformOrigin: 'right',
        ease: 'expo.out',
        duration: 1
      }, 0)
      .to(this.hud.$modeLine, {
        opacity: 0,
        ease: 'alpha',
        duration: 0.3
      }, 0.3)
      .to([this.hud.$modeTextTop, this.hud.$modeTextBottom], {
        yPercent: 0,
        ease: 'expo.out',
        duration: 0.8
      }, 0.35)
      .to(this.grass.material.uniforms.uLight, {
        value: 1,
        duration: 0.75,
        ease: 'power1.out'
      }, 0)
      .fromTo(this.ground.mesh.material.uniforms.uTransition, { value: -0.1 }, { value: 1.2 }, 0)
      .fromTo(this.grass.instancedMesh.material.uniforms.uTransition, { value: -30 }, { value: 35 }, '<')
      .call(() => { this.isAnimatingMode = false }, [], '<85%')
  }

  setRestartColors() {
    const secondaryColor = new Color(store.adjustColor(this.modes[this.modeIndex].grass.getHexString(), -25))
    const darkenSecondaryColor = new Color(store.adjustColor(this.modes[this.modeIndex].grass.getHexString(), -50))
    const darkenColor = new Color(store.adjustColor(this.modes[this.modeIndex].grass.getHexString(), -150))
    
    this.restartColor = `${darkenSecondaryColor.r * 255}, ${darkenSecondaryColor.g * 255}, ${darkenSecondaryColor.b * 255}`

    document.documentElement.style.setProperty('--color-primary', `${darkenColor.r * 255},${darkenColor.g * 255},${darkenColor.b * 255}`)
    document.documentElement.style.setProperty('--color-secondary', `${secondaryColor.r * 255},${secondaryColor.g * 255},${secondaryColor.b * 255}`)
  }

  onRestart() {
    this.physic.isDestroying = true

    // Wait a frame before destroying to prevent error with CANNON
    requestAnimationFrame(() => {
      this.destroy()
    })
  }

  restart() {
    // Enableo physic rAF again
    this.physic.isDestroying = false

    this.rope = new Rope(this)
    this.pinata.restart()

    this.hud.hideOverlay()
    this.pinata.onRestart()
  }

  destroy() {
    this.rope.destroy()
    this.pinata.destroy()

    setTimeout(() => {
      this.restart()
    }, 400)
  }

  checkDevice() {
    if (store.w.w < 768) {
      store.device = 'mobile'
    } else if (store.w.w >= 768 && store.w.w <= 1024) {
      store.device = 'tablet'
    } else {
      store.device = 'desktop'
    }

    return store.device
  }

  resize() {
    store.w = {
      w: window.innerWidth,
      h: window.innerHeight,
      dpr: window.devicePixelRatio
    }

    this.checkDevice()

    this.camera.resize()
    this.grass.resize()
    this.ground.resize()
    this.hud.resize()
    this.renderer.resize()
  }

  update(e) {
    this.fpsGraph && this.fpsGraph.begin()

    this.camera.update()
    this.grass && this.grass.update(e)
    this.hud && this.hud.update(e)
    this.pinata.update()
    this.rope.update()
    this.physic.update()
    this.sound.update()

    this.postProcessing ? this.postProcessing.update(e) : this.renderer.update()
    
    this.fpsGraph && this.fpsGraph.end()
  }
}
