const store = {
  lerp: (s, e, v) => s * (1 - v) + e * v,
  cancelAnimation: (a) => {
    if (a) {
      a.pause()
      a.invalidate()
      a.kill()
    }
  },
  adjustColor: (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
  },
  detect: {
    uA: navigator.userAgent.toLowerCase(),
    get iPadIOS13() {
     return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
    },
    get isMobile() {
      return (/mobi|android|tablet|ipad|iphone/).test(this.uA) && window.innerWidth <= 1024 || this.iPadIOS13
    },
    get isMobileAndroid() {
      return (/android.*mobile/).test(this.uA)
    },
    get isFirefox() {
      return this.uA.indexOf('firefox') > -1
    },
    get isAndroid() {
      return this.isMobileAndroid || !this.isMobileAndroid && (/android/i).test(this.uA)
    },
    get safari() {
      return this.uA.match(/version\/[\d.]+.*safari/)
    },
    get isSafari() {
      return this.safari && !this.isAndroid
    }
  }
}

export default store
