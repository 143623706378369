import { gsap } from 'gsap'
import World from '../World'
import store from '../store'
import Splitting from 'splitting'

export default class Hud {
  constructor() {
    this.bindMethods()
    this.getElems()
    this.events()

    this.clicked = 1
    this.canRestart = true
    this.isAudioEnabled = true
    this.isAboutOpen = false
    this.isCustomOpen = false
    this.pressDuration = 1.2
    this.isModeLoading = false
    this.params = {
      volume: 1,
      noiseSpeed: {
        targ: 0,
        curr: 0
      }
    }

    this.world = new World()
    this.sound = this.world.sound

    this.getBounds()
    this.createAnimations()
  }

  bindMethods() {
    this.onOverlayClick = this.onOverlayClick.bind(this)
    this.onRestart = this.onRestart.bind(this)
    this.onAboutClick = this.onAboutClick.bind(this)
    this.onCustomClick = this.onCustomClick.bind(this)
    this.onKeydown = this.onKeydown.bind(this)
    this.onKeyup = this.onKeyup.bind(this)
    this.onStart = this.onStart.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onAudioClick = this.onAudioClick.bind(this)
  }

  getElems() {
    this.$clickOverlay = document.querySelector('.click-overlay')
    
    // Intro
    this.$intro = document.querySelector('.intro')
    this.$introLogo = document.querySelector('.intro-logo')
    this.$introSound = document.querySelector('.intro-sound')
    this.$introOverlay = document.querySelector('.intro-overlay')
    this.$introBorder = document.querySelector('.intro-border')
    this.$introButton = document.querySelector('.intro-start-xp')
    this.$introInner = document.querySelector('.intro-inner')
    this.$circleLeft = document.querySelector('.circle-left')
    this.$circleRight = document.querySelector('.circle-right')
    this.$introTitle = document.querySelector('.intro-title')
    this.$introText = document.querySelector('.intro-text')

    this.titleChars = new Splitting({
      target: this.$introTitle,
      by: 'chars'
    })[0]

    this.textWords = new Splitting({
      target: this.$introText,
      by: 'words'
    })[0].words

    this.title = this.$introTitle.querySelectorAll('.char')
    this.star = this.$introTitle.querySelectorAll('.svg-wrapper:nth-child(2)')

    // About
    this.$aboutBg = document.querySelector('.about-bg')
    this.$aboutButton = document.querySelector('.about-button')
    this.$aboutContent = document.querySelector('.about-content')

    // Custom
    this.$customBg = document.querySelector('.custom-bg')
    this.$customButton = document.querySelector('.custom-button')
    this.$customContent = document.querySelector('.custom-content')

    // Audio
    this.$audio = document.querySelector('.audio')

    // Restart
    this.$restart = document.querySelector('.restart')
    this.$restartOverlay = document.querySelector('.restart-overlay')
    this.$restartInner = document.querySelector('.restart-inner')
    this.$restartButton = document.querySelector('.restart-button')
    this.$restartBorder = document.querySelector('.restart-border')
    this.$restartLogo = document.querySelector('.restart-logo')

    this.$mode = document.querySelector('.mode')
    this.$modeLine = document.querySelector('.mode-line')
    this.$modeLineCurrent = document.querySelector('.mode-line-current')
    this.$modeTextTop = document.querySelector('.mode-text-top')
    this.$modeTextBottom = document.querySelector('.mode-text-bottom')

    this.$overlay = document.querySelector('.overlay')

    gsap.set([this.$circleLeft, this.$circleRight], { strokeDashoffset: 332 })

    gsap.set(this.$introButton, {
      scale: 0.7,
      opacity: 0
    })

    gsap.set(this.$introBorder, { scale: 0.75 })
    gsap.set(this.$introInner, { scale: 0.7 })

    gsap.set(this.$mode, { opacity: 0 })
    gsap.set(this.$modeLineCurrent, { scaleX: 0 })
    
    gsap.set(this.textWords, { opacity: 0 })
    gsap.set(this.title, { yPercent: 100 })
    gsap.set(this.star, {
      scale: 0,
      rotate: 20
    })
  }

  events() {
    this.$introButton && this.$introButton.addEventListener('click', this.onStart)
    this.$aboutButton && this.$aboutButton.addEventListener('click', this.onAboutClick)
    this.$customButton && this.$customButton.addEventListener('click', this.onCustomClick)
    this.$audio && this.$audio.addEventListener('click', this.onAudioClick)
    this.$restartButton && this.$restartButton.addEventListener('click', this.onRestart)
    this.$clickOverlay && this.$clickOverlay.addEventListener('click', this.onOverlayClick)

    window.addEventListener('keydown', this.onKeydown)
    window.addEventListener('keyup', this.onKeyup)

    if (store.device === 'mobile' || store.device === 'tablet') {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault()
      })

      window.addEventListener('touchstart', this.onTouchStart)
      window.addEventListener('touchend', this.onTouchEnd)
    }
  }

  getBounds() {
    this.aboutButtonSize = this.$aboutButton.getBoundingClientRect()
    this.aboutContentSize = this.$aboutContent.getBoundingClientRect()

    this.customButtonSize = this.$customButton.getBoundingClientRect()
    this.customContentSize = this.$customContent.getBoundingClientRect()

    if (this.isCustomOpen) {
      gsap.set(this.$customBg, {
        width: this.customContentSize.width,
        height: this.customContentSize.height,
        borderRadius: '1rem',
        backgroundColor: '#fff'
      })
    } else {
      gsap.set(this.$customBg, {
        width: this.customButtonSize.width,
        height: this.customButtonSize.height,
        borderRadius: '0.5rem',
        backgroundColor: 'transparent'
      })
    }

    if (this.isAboutOpen) {
      gsap.set(this.$aboutBg, {
        width: this.aboutContentSize.width,
        height: this.aboutContentSize.height,
        borderRadius: '1rem',
        backgroundColor: '#fff'
      })
    } else {
      gsap.set(this.$aboutBg, {
        width: this.aboutButtonSize.width,
        height: this.aboutButtonSize.height,
        borderRadius: '0.5rem',
        backgroundColor: 'transparent'
      })
    }
  }

  onOverlayClick() {
    this.$clickOverlay.classList.add('pointer-events-none')
    this.isAboutOpen && this.onAboutClick()
    this.isCustomOpen && this.onCustomClick()
  }

  onAudioClick() {
    this.isAudioEnabled = !this.isAudioEnabled

    this.$audio.classList.toggle('muted', !this.isAudioEnabled)

    if (this.isAudioEnabled) {
      this.world.sound.audio.play()
      this.world.sound.audio.setVolume(1)
    } else {
      this.world.sound.audio.pause()
      this.world.sound.audio.setVolume(0)
    }
  }

  onCustomClick() {
    this.$customButton.classList.toggle('a', !this.isCustomOpen)

    if (this.isCustomOpen) this.closeCustom()
    else this.openCustom()
  }

  openCustom() {
    this.isCustomOpen = true
    this.world.isStarted = false

    this.$clickOverlay.classList.remove('pointer-events-none')
    
    store.cancelAnimation(this.customCloseAnimation)

    this.customOpenAnimation = gsap.timeline()
    this.customOpenAnimation
      .to(this.$customBg, {
        width: this.customContentSize.width,
        height: this.customContentSize.height,
        borderRadius: '1rem',
        backgroundColor: '#fff',
        ease: 'expo.out',
        duration: 0.8
      }, 0)
  }

  closeCustom() {
    this.isCustomOpen = false
    this.world.isStarted = true
    this.world.form.$button.classList.remove('a')
    
    store.cancelAnimation(this.customOpenAnimation)

    this.customCloseAnimation = gsap.timeline()
    this.customCloseAnimation
      .to(this.$customBg, {
        width: this.customButtonSize.width,
        height: this.customButtonSize.height,
        borderRadius: '0.5rem',
        backgroundColor: 'transparent',
        ease: 'power3.out',
        duration: 0.35
      }, 0)
  }

  onAboutClick() {
    this.$aboutButton.classList.toggle('a', !this.isAboutOpen)

    if (this.isAboutOpen) this.closeAbout()
    else this.openAbout()
  }

  openAbout() {
    this.isAboutOpen = true
    this.world.isStarted = false
    this.$clickOverlay.classList.remove('pointer-events-none')
    
    store.cancelAnimation(this.aboutCloseAnimation)

    this.aboutOpenAnimation = gsap.timeline()
    this.aboutOpenAnimation
    .to(this.$aboutBg, {
      width: this.aboutContentSize.width,
      height: this.aboutContentSize.height,
      borderRadius: '1rem',
      backgroundColor: '#fff',
      ease: 'expo.out',
      duration: 0.8
    }, 0)
  }

  closeAbout() {
    this.isAboutOpen = false
    this.world.isStarted = true
    
    store.cancelAnimation(this.aboutOpenAnimation)

    this.aboutCloseAnimation = gsap.timeline()
    this.aboutCloseAnimation
      .to(this.$aboutBg, {
        width: this.aboutButtonSize.width,
        height: this.aboutButtonSize.height,
        borderRadius: '0.5rem',
        backgroundColor: 'transparent',
        ease: 'power3.out',
        duration: 0.35
      }, 0)
  }

  createAnimations() {
    const tl = gsap.timeline({ repeat: -1 })

    tl
      .fromTo(this.$introInner, {
        boxShadow: '0px 0px 80px 0px rgba(0, 0, 255, 0.70) inset'
      }, {
        boxShadow: '0px 0px 40px 0px rgba(0, 0, 255, 0.70) inset',
        ease: 'linear',
        duration: 2
      })
      .fromTo(this.$introInner, {
        boxShadow: '0px 0px 40px 0px rgba(0, 0, 255, 0.70) inset'
      }, {
        boxShadow: '0px 0px 80px 0px rgba(0, 0, 255, 0.70) inset',
        ease: 'linear',
        duration: 2
      })

    this.loadModeTL = gsap.timeline({
      delay: store.device === 'mobile' ? 0.1 : 0,
      paused: true,
      defaults: {
        duration: this.pressDuration
      },
      onStart: () => {
        for (let i = 0; i < this.world.pinata.deadObjects.length; i++) {
          const object = this.world.pinata.deadObjects[i]
        
          object.velocityX = gsap.utils.random([-2, 2])
          object.velocityY = gsap.utils.random([2.5, 5, 7.5])
        }

        for (let i = 0; i < this.world.pinata.objects.length; i++) {
          const object = this.world.pinata.objects[i]
        
          object.body.velocity.x = object.velocityX
          object.body.velocity.y = object.velocityY
        }
      },
      onUpdate: () => {
        this.params.noiseSpeed.targ += 0.03

        for (let i = 0; i < this.world.pinata.deadObjects.length; i++) {
          const object = this.world.pinata.deadObjects[i]
        
          object.body.velocity.x = object.velocityX
          object.body.velocity.y = object.velocityY
        }

        for (let i = 0; i < this.world.pinata.objects.length; i++) {
          const object = this.world.pinata.objects[i]
        
          object.body.velocity.x = object.velocityX
          object.body.velocity.y = object.velocityY
        }
      },
      onComplete: () => {
        this.isModeLoading = false
        this.world.changeMode()
      }
    })

    this.loadModeTL
      .to(this.$modeLineCurrent, {
        transformOrigin: 'left',
        scaleX: 1,
        ease: 'alpha'
      }, 0)
      .to(this.$modeTextTop, {
        yPercent: -100,
        ease: 'expo.out',
        duration: 0.65
      }, 0)
      .to(this.$modeTextBottom, {
        yPercent: 100,
        ease: 'expo.out',
        duration: 0.9
      }, 0)
      .to(this.$modeLine, {
        opacity: 1,
        ease: 'alpha',
        duration: 0.3
      }, 0.05)
      .to(this.world.camera.instance.position, {
        z: 2.5,
        duration: this.pressDuration,
        ease: 'power1.out'
      }, 0)
      .to(this.world.postProcessing.bloomEffect, {
        intensity: 2.1,
        duration: this.pressDuration,
        ease: 'power1.out'
      }, 0)
      .to(this.world.grass.material.uniforms.uLight, {
        value: 0.5,
        duration: this.pressDuration,
        ease: 'power1.out'
      }, 0)

    this.stopLoadModeTL = gsap.timeline({ paused: true })

    this.stopLoadModeTL
      .to(this.world.camera.instance.position, {
        z: this.world.camera.base.position.z,
        ease: 'power3.out',
        duration: 0.75
      }, 0)
      .to(this.world.postProcessing.bloomEffect, {
        intensity: 1.2,
        ease: 'power2.out',
        duration: 0.75
      }, 0)
      .to([this.$modeTextTop, this.$modeTextBottom], {
        yPercent: 0,
        ease: 'expo.out',
        duration: 0.8
      }, 0.05)
      .to(this.$modeLine, {
        opacity: 0,
        ease: 'alpha',
        duration: 0.2
      }, 0)
      .to(this.$modeLineCurrent, {
        scaleX: 0,
        ease: 'power3.out',
        duration: 0.75
      }, 0)
      .to(this.world.grass.material.uniforms.uLight, {
        value: 1,
        duration: 0.75,
        ease: 'power1.out'
      }, 0)
      // .to(this.world.grass.material.uniforms.uNoiseSpeed, {
      //   value: 1,
      //   ease: 'power1.out',
      //   duration: 0.75
      // }, 0)
  }

  onTouchStart() {
    if (!this.world.isStarted) return

    if (!this.isModeLoading && !this.world.isAnimatingMode && !this.world.pinata.hasFallen) {
      this.isModeLoading = true
      store.cancelAnimation(this.stopLoadModeTL)
      store.cancelAnimation(this.onStartCameraAnimation)

      this.world.pinata.wakeUpObjects()
      this.loadModeTL.restart(true)
    }
  }
  
  onTouchEnd() {
    if (!this.world.isStarted) return

    if (this.isModeLoading && !this.world.isAnimatingMode && !this.world.pinata.hasFallen) {
      this.isModeLoading = false
      store.cancelAnimation(this.loadModeTL)

      this.world.pinata.pushDown()
      this.stopLoadModeTL.restart()
    }
  }

  onKeydown(e) {
    if (!this.world.isStarted) return

    if (e.key === ' ' && !this.isModeLoading && !this.world.isAnimatingMode && !this.world.pinata.hasFallen) {
      this.isModeLoading = true
      store.cancelAnimation(this.stopLoadModeTL)
      store.cancelAnimation(this.onStartCameraAnimation)

      this.world.pinata.wakeUpObjects()
      this.loadModeTL.restart()
    }

    if (e.key === 'Escape') {
      this.isAboutOpen && this.onAboutClick()
      this.isCustomOpen && this.onCustomClick()
    }
  }

  onKeyup(e) {
    if (!this.world.isStarted) return

    if (e.key === ' ' && this.isModeLoading && !this.world.isAnimatingMode && !this.world.pinata.hasFallen) {
      this.isModeLoading = false
      store.cancelAnimation(this.loadModeTL)

      this.world.pinata.pushDown()
      this.stopLoadModeTL.restart()
    }
  }

  onStart() {
    if (this.clicked === 4) return

    gsap.to([this.$circleLeft, this.$circleRight], {
      strokeDashoffset: 332  - 332 / 3 * this.clicked,
      ease: this.clicked === 3 ? 'expo.out' : 'elastic.out(1, 0.5)',
      duration: 1
    })

    if (this.clicked === 1) {
      this.world.addGyroscope()
    }

    this.clicked++

    if (this.clicked === 4) {
      this.world.onStart()
  
      const tl = gsap.timeline({
        delay: 0.2,
        defaults: {
          ease: 'alpha',
          duration: 0.5
        }
      })

      this.onStartCameraAnimation = gsap.fromTo(this.world.camera.instance.position, {
        z: -8
      }, {
        z: this.world.camera.base.position.z,
        ease: 'power3.out',
        duration: 2.5,
        delay: 1.6
      })
  
      tl
        .to(this.$introBorder, {
          scale: 0.97,
          ease: 'power3.in',
          duration: 1
        }, 0)
        .to(this.$intro, {
          pointerEvents: 'none',
          opacity: 0,
        }, 0.6)
        .to(this.$introButton, {
          scale: 0.9,
          ease: 'power3.in',
          duration: 1
        }, 0)
        .to(this.textWords, {
          opacity: 0,
          ease: 'alpha',
          duration: 0.4
        }, 0.4)
        .to(this.$introInner, {
          scale: 0.85,
          ease: 'power3.in',
          duration: 1
        }, 0)
        .to(this.$overlay, {
          opacity: 0,
          duration: 1
        }, 1.5)
        .to(this.$mode, {
          opacity: 1
        }, '<90%')
        // .fromTo(this.world.camera.instance.position, {
        //   z: -8
        // }, {
        //   z: this.world.camera.base.position.z,
        //   ease: 'power3.out',
        //   duration: 2.5
        // }, 1.4)
        .call(() => {
          this.world.isStarted = true
          this.world.pinata.onStart()
        }, [], 0.75)
        .call(() => {
          this.world.isAnimatingMode = false
        }, [], 3)
    }

  }

  showLoader() {
    this.loaderTL = gsap.timeline()

    this.loaderTL
      .to(this.$introLogo, {
        opacity: 1,
        ease: 'alpha',
        duration: 0.9
      }, 0.4)
      .to(this.$introSound, {
        opacity: 0.5,
        ease: 'alpha',
        duration: 0.9
      }, 0.8)
      .to(this.$introOverlay, {
        opacity: 0,
        ease: 'alpha',
        duration: 1.2
      }, 0)
      .fromTo(this.$introBorder, { scale: 0.85 }, {
        scale: 1,
        opacity: 1,
        ease: 'power3.out',
        duration: 1.5
      }, 0)
      .to(this.$introButton, {
        scale: 1,
        opacity: 1,
        ease: 'expo.out',
        duration: 1.7
      }, 0)
      .fromTo(this.$introInner, { scale: 0.6 }, {
        scale: 1,
        ease: 'expo.out',
        duration: 1.7
      }, 0)
      .to(this.title, {
        yPercent: 0,
        stagger: 0.025,
        ease: 'expo.out',
        duration: 1.7
      }, 0.3)
      .to(this.textWords, {
        opacity: 1,
        stagger: {
          from: 'random',
          each: 0.05
        },
        ease: 'alpha',
        duration: 1.3
      }, 0.43)
      .to(this.star, {
        scale: 1,
        rotate: 0,
        ease: 'expo.out',
        duration: 1
      }, 1.2)
  }

  showRestartUI() {
    this.world.isStarted = false

    this.restartGradient = gsap.timeline({
      repeat: -1
    })

    this.restartGradient
      .fromTo(this.$restartInner, {
        boxShadow: '0px 0px 80px 0px rgba(' + this.world.restartColor + ', 1) inset'
      }, {
        boxShadow: '0px 0px 40px 0px rgba(' + this.world.restartColor + ', 1) inset',
        ease: 'linear',
        duration: 1
      })
      .fromTo(this.$restartInner, {
        boxShadow: '0px 0px 40px 0px rgba(' + this.world.restartColor + ', 1) inset'
      }, {
        boxShadow: '0px 0px 80px 0px rgba(' + this.world.restartColor + ', 1) inset',
        ease: 'linear',
        duration: 1
      })

    store.cancelAnimation(this.frequencyTL)

    const tl = gsap.timeline({ delay: 1 })

    tl
      .to(this.$restart, {
        autoAlpha: 1,
        ease: 'alpga',
        duration: 1
      }, 0)
      .fromTo(this.$restartLogo, { opacity: 0 }, {
        opacity: 1,
        ease: 'alpha',
        duration: 0.6
      }, 0.9)
      .fromTo(this.$restartBorder, {
        scale: 0.85,
        opacity: 0
      }, {
        scale: 1,
        opacity: 1,
        ease: 'power3.out',
        duration: 1.5
      }, 0.3)
      .fromTo(this.$restartButton, {
        opacity: 0,
        scale: 0.8
      }, {
        scale: 1,
        opacity: 1,
        ease: 'expo.out',
        duration: 1.7
      }, 0.4)
      .fromTo(this.$restartInner, { scale: 0.7 }, {
        scale: 1,
        ease: 'expo.out',
        duration: 1.7
      }, 0.4)
      .to(this.world.camera.instance.position, {
        z: 0,
        ease: 'power1.out',
        duration: 10
      }, 0.1)
      .fromTo(this.sound.volumeTween, { progress: 1 }, {
        progress: 0,
        ease: 'linear',
        duration: 0.8,
        onComplete: () => (
          this.sound.audio && this.sound.audio.pause()
        )
      }, 0)

    if (this.sound.oscillator) {
      tl.to(this.sound.oscillator.frequency, {
        value: 110,
        ease: 'linear',
        duration: 0.8
      }, 0)

    }

      tl.set(this.$restart, { pointerEvents: 'all' }, 1)

    this.restartUITl = tl
  }

  onRestart() {
    if (!this.canRestart) return

    this.canRestart = false

    const tl = gsap.timeline({
      onComplete: () => {
        gsap.set(this.$restart, {
          pointerEvents: 'none',
          autoAlpha: 0
        })

        this.canRestart = true
        
        this.sound.audio && this.sound.audio.stop()
        this.world.onRestart()
      }
    })

    tl
      .fromTo(this.sound.volumeTween, { progress: 1 }, {
        progress: 0,
        ease: 'linear',
        duration: 0.8
      }, 0)
      .to(this.$overlay, {
        opacity: 1,
        ease: 'alpha',
        duration: 0.5
      }, 0)
  }

  hideOverlay() {
    store.cancelAnimation(this.restartUITl)
    
    this.restartGradient.pause()

    const tl = gsap.timeline({
      delay: 1,
      onStart: () => {
        this.world.isAnimatingMode = true
        this.world.camera.setBasePosition()
        this.world.camera.canMove = true

        this.sound.audio && this.world.hud.isAudioEnabled && this.sound.audio.play()
        this.sound.createFrequencyAnimation()
      },
      onComplete: () => {
        this.world.isAnimatingMode = false
      }
    })
    
    tl
      .fromTo(this.sound.volumeTween, { progress: 0 }, {
        progress: 1,
        ease: 'linear',
        duration: 0.8
      }, 0)
      .to(this.$overlay, {
        opacity: 0,
        ease: 'alpha',
        duration: 0.6
      }, 0)
      .fromTo(this.world.camera.instance.position, {
        z: -8
      }, {
        z: this.world.camera.base.position.z,
        ease: 'power3.out',
        duration: 2
      }, 0)
      .call(() => {
        this.world.isStarted = true
      }, [], 0.5)
  }

  resize() {
    this.getBounds()
  }

  update() {
    this.params.noiseSpeed.curr = store.lerp(this.params.noiseSpeed.curr, this.params.noiseSpeed.targ, 0.1)

    this.world.grass.instancedMesh.material.uniforms.uNoiseSpeed.value = this.params.noiseSpeed.curr
  }
}
