import World from './World/World'
import Resources from './World/Resources'
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

export default class App {
  constructor() {
    // Bind methods
    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)

    // Add GSAP CustomEase plugin
    gsap.registerPlugin(CustomEase)
    CustomEase.create('alpha', '.25, .46, .45, .9')
    
    window.addEventListener('resize', this.resize)
    
    this.resources = new Resources()
    this.resources.load().then(() => {
      this.world = new World({ sources: this.resources.sources })
    })
    
    window.requestAnimationFrame(this.update)
  }

  resize() {
    this.world && this.world.resize()
  }

  update(e) {
    this.world && this.world.update(e)

    window.requestAnimationFrame(this.update)
  }
}
